<template>
  <div class="curriculumPlanning">
    <a-modal
      v-model="visibleModal"
      class="modalDetailRpp"
      title="Detail RPP"
      :width="1200"
      @cancel="toggleModal()"
      centered
      :footer="null"
    >
      <form-rpp
        :propData="dataDetail"
        :weeks="weeks"
        :currentSubject="currentDetail.subject"
        :currentLevel="currentDetail.level"
        :fileTopik="fileTopik"
        :fileList="fileList"
        :newFileList="[]"
        isReadOnly
      />
    </a-modal>
    <EmptyState v-if="!this.months.length" heading="No Active Academic Year" description="Please contact admin to activate or create academic year." />
    <div v-else>
      <div class="d-md-flex mb-3">
        <div :class="isMobile ? 'w-100' : 'mr-3'">
          <a-select :class="isMobile ? 'w-100' : null" @change="fetchData" v-model="idMonth" size="large" style="min-width: 200px;">
            <a-select-option v-for="month in months" :value="month.select" :key="month.select">{{month.month}}{{currentMonth === month.key ? ' (current)' : ''}}</a-select-option>
          </a-select>
        </div>
        <div :class="isMobile ? 'w-100' : null">
          <a-button :icon="isOpen ? 'folder' : 'folder-open'" type="primary" size="large" @click.prevent="actionExpandedRowKeys(isOpen ? 'close' : 'open')">
            {{ isOpen ? 'Collapse' : 'Expand'}} All Data
          </a-button>
        </div>
        <div class="ml-auto">
          <a-button
            :class="isMobile ? ['w-100', 'mt-3'] : null"
            size="large"
            @click.prevent="refreshTable"
          >
            <a-icon type="sync" :spin="loadingRefresh" />
            Refresh
          </a-button>
        </div>
      </div>
      <div>
        <a-table :columns="parentColumns" :data-source="dataTable" class="components-table-demo-nested hasan-table-overflow" bordered :loading="loadingTable" @change="handleTableChange" :pagination="pagination" :expanded-row-keys.sync="expandedRowKeys">
          <a-table slot="expandedRowRender" slot-scope="value" :columns="childrenColumns" :data-source="value.mata_pelajarans" :pagination="false">
            <div slot="rppProgress" slot-scope="value">
              <a-progress style="width: 70%" :percent="Number(value.toFixed(2))"/>
            </div>
            <div slot="week" slot-scope="value, record">
              <template v-if="Boolean(value.status)">
                <a-icon @click.prevent="detailRpp(value.id, { subject: record.nama, level: record.level })" type="check-circle" theme="twoTone" two-tone-color="#97cd21" style="font-size: 20px;"></a-icon>
                <!-- <span style="position: absolute;" class="mr-2">
                  <a-icon
                    class="ml-2"
                    type="eye"
                  />
                </span> -->
              </template>
              <a-icon v-else type="close-circle" theme="twoTone" two-tone-color="red" style="font-size: 20px;"></a-icon>
            </div>
          </a-table>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
const FormRpp = () => import('@/views/Teacher/Planning/New/RPP/Form')
// import { fakeTeacherPlanningProgressData } from '@/helpers/fakeData.js'
// import moment from 'moment'
const EmptyState = () => import('@/components/app/EmptyState')
const parentColumns = [
  {
    title: 'Teacher Name',
    dataIndex: 'nama',
    scopedSlots: { customRender: 'teacherName' },
    align: 'center',
  },
]

export default {
  components: { EmptyState, FormRpp },
  data() {
    return {
      parentColumns,
      data: [],
      dataTable: [],
      pagination: { current: 1 },
      loadingTable: false,
      loadingRefresh: false,
      type: 'mid',
      months: [],
      idMonth: null,
      totalWeek: 0,
      visibleModal: false,
      dataDetail: {},
      weeks: [],
      currentDetail: {
        subject: null,
        level: null,
      },
      fileList: [],
      fileTopik: [],
      expandedRowKeys: [],
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      // console.log(this.expandedRowKeys)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetchData()
    },
    handleChangeType() {
      this.fetchData()
    },
    refreshTable() {
      this.fetchData()
    },
    async fetchMonths() {
      try {
        this.months = await this.$store.dispatch('schoolYear/FETCH_MONTHS_LIST')
        if (this.months.length) {
          const month = this.months.find(m => m.key === this.currentMonth)
          this.idMonth = month?.select ?? this.months[0].select
        }
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchData() {
      try {
        this.loadingTable = true
        this.loadingRefresh = true
        const { pagination } = this
        const { data, total, totalWeek } = await this.$store.dispatch('curriculum/FETCH_DATA_PROGRESS_RPP', { select: this.idMonth, page: pagination.current })
        this.dataTable = data
        this.expandedRowKeys = this.dataTable.map(data => data.key)
        const oldPagination = { ...this.pagination }
        oldPagination.total = total
        this.pagination = oldPagination
        this.totalWeek = totalWeek
        this.loadingTable = false
        this.loadingRefresh = false
        // console.log(this.dataTable)
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    actionExpandedRowKeys(action = 'open') {
      if (action === 'open') this.expandedRowKeys = this.dataTable.map(data => data.key)
      else if (action === 'close') this.expandedRowKeys = []
      // this.isOpen = !this.isOpen
    },
    async detailRpp(id, { subject, level }) {
      try {
        // console.log(subject, level)
        const { data, weeks } = await this.$store.dispatch('curriculum/FETCH_DETAIL_RPP_BY_ID', { id })
        this.dataDetail = data
        this.fileList = data.files.map(file => {
          return {
            id: file.id,
            key: file.id,
            deskripsi_lampiran: file.deskripsi_lampiran,
            file: [file],
          }
        })
        this.fileTopik = []
        data.topik.forEach(topik => {
          this.fileTopik = this.fileTopik.concat(topik.files)
        })
        this.currentDetail = { subject, level }
        this.weeks = weeks
        this.visibleModal = true
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    toggleModal() {
      this.visibleModal = !this.visibleModal
    },
  },
  async mounted() {
    try {
      await this.fetchMonths()
      await this.fetchData()
    } catch (err) {
      console.log(err)
    }
    // for (let i = 0; i < 12; i++) {
    //   const payload = {
    //     key: i,
    //     month: moment.months(i),
    //   }
    //   this.months.push(payload)
    // }
    // this.fetchDataTable({
    //   page: this.pagination.current,
    //   type: this.type,
    // })
  },
  computed: {
    isOpen() {
      const checkData = this.dataTable.filter(dat => !this.expandedRowKeys.includes(dat.key))
      return Boolean(!checkData.length)
    },
    user() { return this.$store.state.user.user },
    childrenColumns() {
      const fixed = this.totalWeek > 4 ? 'left' : null
      const week = {
        title: 'Weeks',
        align: 'center',
        width: 400,
        children: [],
      }

      for (let i = 1; i <= this.totalWeek; i++) {
        const payload = {
          title: `Week ${i}`,
          dataIndex: `week${i}`,
          scopedSlots: { customRender: 'week' },
          align: 'center',
        }
        week.children.push(payload)
      }
      return [
        {
          title: 'Subject',
          dataIndex: 'nama',
          scopedSlots: { customRender: 'subject' },
          width: 200,
          align: 'center',
          fixed,
        },
        {
          title: 'Level',
          dataIndex: 'level',
          scopedSlots: { customRender: 'level' },
          width: 100,
          align: 'center',
          fixed,
        },
        week,
        {
          title: 'RPP Progress',
          dataIndex: 'rppProgress',
          scopedSlots: { customRender: 'rppProgress' },
          width: 250,
          align: 'center',
        },
      ]
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    currentMonth() {
      let currentMonth = null
      const current = moment().format('YYYY-MM')
      for (let i = 0; i < this.months.length; i++) {
        const month = this.months[i]
        if (current === month.select) currentMonth = month.key
      }
      return currentMonth
    },
  },
}
</script>

<style lang="scss">
// .curriculumPlanning {
// }
.modalDetailRpp {
  .ant-modal-content {
    min-height: 500px;
  }
}
</style>
